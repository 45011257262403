import { useLayoutEffect, useState, useRef } from 'react';

const useAvailableDimension = () => {
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);
  const [availableWidth, setAvailableWidth] = useState(window.innerWidth);
  const [fixedHeight, setFixedHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const progressBarRef = useRef<HTMLDivElement>(null);

  const updateDimensions = () => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const progressBarHeight = progressBarRef.current?.offsetHeight || 0;
    const footerHeight = footerRef.current?.offsetHeight || 0;
    const totalFixedHeight = headerHeight;

    setFixedHeight(totalFixedHeight);
    setAvailableHeight(window.innerHeight - (headerHeight + footerHeight));
    setAvailableWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  return { availableHeight, availableWidth, fixedHeight, headerRef, footerRef, progressBarRef };
};

export default useAvailableDimension;