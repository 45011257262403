import React from 'react';
import { RoutePath } from "../../AppRoutes";
import { useLocation } from "react-router-dom";

const progressPercentage = {
  [RoutePath.home]: "1%",
  [RoutePath.gPL]: "3%",
  [RoutePath.info]: "5%",
  [RoutePath.greetings]: "20%",
  [RoutePath.motivation]: "40%",
  [RoutePath.change]: "45%",
  [RoutePath.stateOfResidence]: "55%",
  [RoutePath.greatNews]: "60%",
  [RoutePath.bMICalculator]: "65%",
  [RoutePath.healthHistory]: "70%",
  [RoutePath.comorbiditiesForm]: "78%",
  [RoutePath.antiObesityMedicationQuestion]: "85%",
  [RoutePath.currentMedications]: "90%",
  [RoutePath.qualified]: "100%",
  [RoutePath.disqualifyed]: "100%",
};

// Wrap your component with React.forwardRef
const Progress = React.forwardRef<HTMLDivElement, { percentage?: string }>((props, ref) => {
  const { percentage } = props;
  const location = useLocation();
  const path = location.pathname;
  const progress = progressPercentage[path] || "1%";

  return (
    // Attach the ref to a DOM element, in this case, the root div
    <div ref={ref} className="xs:mx-[2rem] md:mx-[2rem] lg:mx-[5rem] mx-[5rem] h-2 bg-gray-300 mt-8">
      <div
        className="h-2 relative bg-gradient-to-r from-yellow-400 to-orange-500 rounded-full"
        style={{ width: percentage || progress }}
      >
        <span style={{ bottom: "6px" }} className="absolute right-0 text-[#0B1838]">
          {percentage || progress}
        </span>
      </div>
    </div>
  );
});

export default Progress;