import moment from "moment";

export const combinedOperation = (data: Record<string, any>, arr: string[], item: string, questions: string, height: string, HomePhone: string, dateField: string): Record<string, any> => {
  // Remove unwanted fields
  for (let i = 0; i < arr.length; i++) {
    delete data[arr[i]];
  }

  // Ensure item is a string
  if (item !== "") {
    data[item] = data[item].toString();
  }

  // Format height (remove apostrophes)
  if (height !== "") {
    data[height] = data[height].split("'").join("");
  }

  // Format phone number by removing non-numeric characters
  if (HomePhone !== "") {
    data[HomePhone] = data[HomePhone].replace(/[^\d]/g, "");
  }

  // Format date to "YYYY-MM-DDT00:00:00.000Z"
  data[dateField] = moment(data[dateField]).format("MM/DD/YYYY");

  return data;
};
