import { SET_FORM_FIELD, SET_FORM_RESET } from "../actions/actionTypes";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  state: "",
  heightFt: "",
  heightIn: "",
  currentWeight: "",
  currentBMI: "",
  goalWeight: "", 
  goalBMI: "",
  comorbidities: [],
  healthHistory: "",
  antiObesityMedications: undefined,
  currentMedications: [],
};
interface ActionType {
  type: string;
  payload: {
    field: string;
    value: any;
  };
}

const formReducer = (state = initialState, action: ActionType) => {
  const { field, value } = action.payload || {};
  switch (action.type) {
    case SET_FORM_FIELD:
      return {
        ...state,
        [field]: value,
      };
    case SET_FORM_RESET:
      return initialState;
    default:
      return state;
  }
};

export default formReducer;