import React, { useState, useCallback, Suspense, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import { debounce } from "lodash";
import emailValidation from "./validations/emailValidation";
import { useNavigate } from "react-router-dom";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const genderOptions = ["Choose your gender", "Male", "Female"];

interface InfoProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const Info: React.FC<InfoProps> = ({ availableHeight, availableWidth, marginTop }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [dateError, setDateError] = useState("");
  const [dateInput, setDateInput] = useState("");

  const handFieldUpdate = useCallback(
    (field: string, value: string) => {
      const payload = { field, value };
      dispatch(setFormValue(payload));
    },
    [dispatch]
  );

  const validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    return name.length >= 2 && regex.test(name);
  };

  const isValidDate = (month: number, day: number, year: number): string => {
    const today = new Date();
    const enteredDate = new Date(year, month - 1, day);

    if (month < 1 || month > 12) return "Month must be between 01 and 12.";
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) return `Day must be between 01 and ${daysInMonth} for this month.`;
    if (enteredDate > today) return "Date of birth cannot be in the future.";
    const ageLimitDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
    if (enteredDate > ageLimitDate) return "You must be at least 18 years old.";
    return "";
  };

  // Date input change handler with formatting
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Strip non-numeric characters
    let formattedDate = "";
    const cursorPosition = e.target.selectionStart || 0;

    // Format date as MM/DD/YYYY
    if (input.length >= 2) {
      formattedDate = `${input.slice(0, 2)}`; // MM
      if (input.length >= 4) {
        formattedDate += `/${input.slice(2, 4)}`; // DD
        if (input.length >= 8) {
          formattedDate += `/${input.slice(4, 8)}`; // YYYY
        } else if (input.length > 4) {
          formattedDate += `/${input.slice(4)}`;
        }
      } else if (input.length > 2) {
        formattedDate += `/${input.slice(2)}`;
      }
    } else {
      formattedDate = input;
    }

    // Update Redux state
    handFieldUpdate("dateOfBirth", formattedDate);

    // Perform validation
    if (formattedDate.length === 10) {
      const [month, day, year] = formattedDate.split("/").map((part) => parseInt(part, 10));
      const error = isValidDate(month, day, year);
      setDateError(error || ""); // Set or clear error
    } else {
      setDateError("");
    }

    // Adjust cursor to prevent jumpiness
    const slashPositions = [2, 5];
    let adjustedPosition = cursorPosition;
    if (slashPositions.includes(cursorPosition)) adjustedPosition += 1;
    e.target.setSelectionRange(adjustedPosition, adjustedPosition);
  };

  const validateEmail = (email: string) => {
    const isValid = emailValidation(email);
    setEmailError(!isValid ? "Please enter a valid email address" : "");
  };

  const debouncedValidateEmail = useCallback(
    debounce((email: string) => {
      validateEmail(email);
    }, 300),
    []
  );

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("firstName", value);
    setFirstNameError(!validateName(value) ? "First name must be at least 2 characters and contain only letters." : "");
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("lastName", value);
    setLastNameError(!validateName(value) ? "Last name must be at least 2 characters and contain only letters." : "");
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    handFieldUpdate("email", value);
    debouncedValidateEmail(value);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      if (!emailError && !firstNameError && !lastNameError && !dateError && form.firstName && form.email && form.dateOfBirth) {
        navigate("/greetings");
      } else {
        alert("Please ensure all details are valid.");
      }
    },
    [emailError, firstNameError, lastNameError, dateError, form, navigate]
  );

  const desktopFontConfig = useMemo(
    () => ({
      heading: 30,
      buttonText: 19,
      label: 16,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 24,
      buttonText: 14,
      label: 13,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  return (
    <div className="flex items-center justify-center xs:px-8 md:px-0">
      <div className="md:max-w-xl lg:max-w-6xl w-full xs:my-12 md:mt-16">
        <h2 className="xs:text-xl md:text-3xl font-medium xs:text-left md:text-center mb-8 text-[#0B1838]" style={{ fontSize: fontSizes.heading }}>
          Please share your information to get started.
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          {/* First and Last Name Fields */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="firstName" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg mb-2" style={{ fontSize: fontSizes.label }}>
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={form.firstName}
                onChange={handleFirstNameChange}
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  firstNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your first name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {firstNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {firstNameError}
                </p>
              )}
            </div>
            {/* Last Name Field */}
            <div>
              <label htmlFor="lastName" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg mb-2" style={{ fontSize: fontSizes.label }}>
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={form.lastName}
                onChange={handleLastNameChange}
                className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                  lastNameError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
                }`}
                placeholder="Enter your last name"
                required
                style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
              />
              {lastNameError && (
                <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                  {lastNameError}
                </p>
              )}
            </div>
          </div>

          <div>
      <label htmlFor="dateOfBirth" className="block text-sm mb-2">
        Date of Birth
      </label>
      <input
        id="dateOfBirth"
        onChange={handleDateChange}
        className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 ${
          dateError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
        }`}
        type="text"
        maxLength={10}
        value={form.dateOfBirth} // Binding to Redux state
        placeholder="MM/DD/YYYY"
        required
        style={{ background: "#FBFBFB" }}
        dir="ltr"
      />
      {dateError && (
        <p className="text-red-500 text-sm mt-1">
          {dateError}
        </p>
      )}
    </div>
          {/* Gender Field */}
          <div className="relative">
            <label htmlFor="gender" className="block text-sm text-[#0B1838] xs:text-sm xl:text-lg mb-2" style={{ fontSize: fontSizes.label }}>
              Gender Assigned At Birth
            </label>
            <select
              id="gender"
              className="w-full xs:px-3 md:px-6 py-2 border text-[#0B1838] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none"
              onChange={(e) => handFieldUpdate("gender", e.target.value)}
              value={form.gender}
              style={{
                fontSize: fontSizes.inputText,
                borderRadius: "10px",
                paddingRight: "2.5rem",
                background: "#FBFBFB",
              }}
            >
              {genderOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center xs:pr-[1rem] md:pr-[1.6rem]" style={{ top: "40%" }}>
              <svg className="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block xs:text-sm xl:text-lg text-[#0B1838] mb-2" style={{ fontSize: fontSizes.label }}>
              Email
            </label>
            <input
              id="email"
              type="email"
              value={form.email}
              onChange={handleEmailChange}
              placeholder="Enter your email address"
              className={`w-full xs:px-3 md:px-6 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 ${
                emailError ? "border-red-500 focus:ring-red-500" : "border-gray-300 focus:ring-indigo-500"
              }`}
              required
              style={{ fontSize: fontSizes.inputText, background: "#FBFBFB" }}
            />
            {emailError && (
              <p className="text-red-500 text-sm mt-1" style={{ fontSize: fontSizes.errorText }}>
                {emailError}
              </p>
            )}
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <Suspense fallback={<div>Loading...</div>}>
              <SectionActionButton text="Next" onClick={handleSubmit} style={{ fontSize: fontSizes.buttonText }} />
            </Suspense>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Info;
