import Image from "../assets/disqWeb.webp";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectFormState } from "../lib/store/selectors/formSelector";
import SectionActionButton from "../components/microcomponents/SectionActionButton";
import { setFormReset } from "../lib/store/actions/formAction";
import { useNavigate } from "react-router-dom";
import disqMobile from "../assets/disqMob.webp";
import { useMemo } from "react";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

export default function DisQualified({
  availableHeight,
  availableWidth,
  imageScale,
}: any) {
  const form = useSelector(selectFormState, shallowEqual);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
    const desktopFontConfig = useMemo(
    () => ({
      mainText: 120, // Base font size in px for <h1>
      subText: 30, // Base font size in px for <p> acting as <h2>
      bodyText: 25, // Base font size in px for regular <p>
      button: 19, // Base font size in px for buttons
    }),
    []
  );
  
  const mobileFontConfig = useMemo(
    () => ({
      mainText: 28, // Base font size in px for <h1>
      subText: 17, // Base font size in px for <p> acting as <h2>
      bodyText: 11, // Base font size in px for regular <p>
      button: 14, // Base font size in px for buttons
    }),
    []
  );
  
  const fontSizes = useResponsiveFonts(
    desktopFontConfig,
    mobileFontConfig,
    availableHeight,
    availableWidth,
    800,  // baseHeight for desktop
    1200, // baseWidth for desktop
    600,  // baseHeight for mobile
    400,  // baseWidth for mobile
    12,   // minFontSize
    72,   // maxFontSize
    768   // mobile breakpoint
  );

  const handleBack = () => {
    dispatch(setFormReset());
    navigate("/", { replace: true });
  };

  return (
    <div className="xs:h-auto md:h-screen grid grid-cols-1 lg:grid-cols-2 xs:mt-8 md:mt-0" style={{ height: availableWidth < 600 ? "100%" : availableHeight - 38 }}>
      <div className="flex flex-col justify-center items-start xs:px-10 lg:px-20 bg-white ">
        <div className="w-full mt-4">
          <h1 style={{ fontSize: fontSizes.mainText, lineHeight: 1.2 }} className="font-bold text-[#0B1838] mb-4">
            Thanks for completing our quiz, <span className="text-[#0B1838]">{`${form.firstName}`}</span>.
          </h1>

          <p style={{ fontSize: fontSizes.subText, lineHeight: 1.3 }} className="bg-gradient-to-r from-[#F06735] to-[#F8E231] text-transparent bg-clip-text ">
            Unfortunately you're not eligible for GLP-1 at this time.
          </p>

          <p style={{ fontSize: fontSizes.bodyText, lineHeight: 1.5 }} className="mt-2 text-[#0B1838]">
            Our program is not appropriate for patients with certain medical conditions.
          </p>

          <SectionActionButton
            onClick={handleBack}
            text="Back to Home"
            className="mt-4 px-6 py-3 text-lg w-full md:w-[80%] border border-[#333] hover:border-none text-[#0B1838] rounded-md hover:bg-gradient-to-r from-[#F8E231] to-[#F06735] hover:text-white"
            fontSize={fontSizes.button}
            disabled={false}
          />
          
          <div className="md:hidden xs:block xs:pt-8 !xs:px-0">
            <img src={disqMobile} alt="Celly Health" className="object-cover w-full h-full" />
          </div>


        </div>
      </div>
      <div className="relative">
        <img
          src={Image}
          alt="People at the beach"
          className="hidden sm:block w-full h-full mt-5 object-cover md:mb-[-5rem] lg:mb-[-2.8rem] xl:mb-0"
        />
      </div>
    </div>
  );
}
