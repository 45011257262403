
import React, { useMemo, useCallback, useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setFormValue } from "../lib/store/actions/formAction";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { useNavigate } from "react-router-dom";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

const disqualifyingConditions: string[] = [
  "Pregnant or currently breastfeeding",
  "Active cancer, chemotherapy, or radiation in the last 6 months",
  "Active drug or alcohol abuse",
  "Serious mental health conditions",
  "Kidney disease or transplant",
  "Active hepatitis or liver disease",
  "Daily steroid use (e.g. prednisone)",
  "Heart attack, stroke, or heart conditions that limit daily activity in the last 6 months",
  "Active gallbladder disease",
  "History of bariatric surgery"
];

interface HealthHistoryProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const HealthHistory: React.FC<HealthHistoryProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Local state for selected medications
  const [selectedmedications, setSelectedmedications] = useState<string>(form.healthHistory || '');
  const [isNoneSelected, setIsNoneSelected] = useState<boolean>(false);

  const handleNoneSelected = useCallback(() => {
    let value = "No exsisting disqualifying factors";
    setSelectedmedications(value);
    dispatch(setFormValue({ field: "healthHistory", value: value }));
    setIsNoneSelected(true);
    navigate("/comorbiditiesform");
  }, [navigate, dispatch]);

  const togglemedications = useCallback((condition: string) => {
    dispatch(setFormValue({ field: "healthHistory", value: condition }));
    setIsNoneSelected(false);
    setSelectedmedications(condition);
    navigate("/disqualified");
  }, [navigate, dispatch]);
  useEffect(() => {
    if (selectedmedications === "No exsisting disqualifying factors") {
      setIsNoneSelected(true);
    }
  }, [selectedmedications, form.healthHistory]);

  const desktopFontConfig = useMemo(
    () => ({
      heading: 32,
      subheading: 20,
      buttonText: 18,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 19,
      subheading: 14,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);
  return (
    <div
      className="flex flex-col items-center xs:mt-6 md:mt-16 xs:mb-[5rem] md:mb-0"
      style={{
        paddingLeft: "1rem",
        paddingRight: "1rem",
      }}
    >
      <div className="xs: w-[90%] md:w-full text-center lg:max-w-6xl mx-auto">
        {/* Heading */}
        <h2 className="font-bold text-left md:text-center text-[#0B1838]" style={{ fontSize: fontSizes.heading }}>
          Do any of the following apply to you?
        </h2>

        {/* Subheading/Paragraph */}
        <p className="text-gray-500 xs:mb-2 md:mb-4 text-left md:text-center" style={{ fontSize: fontSizes.subheading }}>
          It's important to understand your health history to determine if you're eligible.
        </p>

       
          <button
            className={`w-full mb-4 mt-2 px-4 py-3 text-left md:text-center border-2 rounded-lg transition-colors ${
              isNoneSelected ? "bg-[#E6EAF580] border-[#0B1838] text-[#333]" : "bg-gray-50 border-gray-300 hover:bg-gray-100 text-gray-800"
            }`}
            onClick={handleNoneSelected}
            style={{ fontSize: fontSizes.buttonText }}
          >
            None of these
          </button>
        

        {/* medications Selection */}
        <div className="xs:mb-[2.5rem] md:mb-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {disqualifyingConditions.map((condition, index) => (
              <button
                key={index}
                className={`w-full px-4 py-3 text-[#0B1838] text-left border-2 rounded-lg transition-colors ${
                  selectedmedications.includes(condition) ? "bg-[#E6EAF580] border-[#0B1838] text-[#333]" : "bg-gray-50 border-gray-300 hover:bg-gray-100"
                }`}
                onClick={() => togglemedications(condition)}
                style={{ fontSize: fontSizes.buttonText }}
              >
                {condition}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthHistory;
