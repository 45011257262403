import React, { useCallback, useMemo } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectFormState } from "../lib/store/selectors/formSelector";
import { setFormValue } from "../lib/store/actions/formAction";
import useResponsiveFonts from "../hooks/useResponsiveFonts";

interface AntiObesityMedicationQuestionProps {
  availableHeight: number;
  availableWidth: number;
  marginTop?: number;
}

const AntiObesityMedicationQuestion: React.FC<AntiObesityMedicationQuestionProps> = ({ availableHeight, availableWidth }) => {
  const form = useSelector(selectFormState, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFieldUpdate = useCallback(
    (value: boolean) => {
      dispatch(setFormValue({ field: "antiObesityMedications", value }));
      const currentBMI = form.currentBMI;
      const selectedComorbidities = form.comorbidities || [];

      if (currentBMI >= 27 && value === false) {
        navigate("/qualified");
      } else if (currentBMI >= 27 && value === true) {
        navigate("/currentmedications");
      } else if (currentBMI >= 25 && currentBMI < 27) {
        if (selectedComorbidities.length >= 1 && !selectedComorbidities.includes("I’m not currently having any of the given comorbidities") && value === true) {
          navigate("/currentmedications");
        } else if (selectedComorbidities.length >= 1 && !selectedComorbidities.includes("I’m not currently having any of the given comorbidities") && value === false) {
          navigate("/qualified");
        } else {
          navigate("/disqualified");
        }
      } else if (currentBMI < 25) {
        if (value === true) {
          navigate("/currentmedications");
        } else {
          navigate("/disqualified");
        }
      }
    },
    [dispatch, navigate, form.currentBMI, form.comorbidities]
  );

  const selectedValue = form.antiObesityMedications;

  const desktopFontConfig = useMemo(
    () => ({
      heading: 28,
      question: 28,
      buttonText: 20,
    }),
    []
  );

  const mobileFontConfig = useMemo(
    () => ({
      heading: 20,
      question: 18,
      buttonText: 14,
    }),
    []
  );

  const fontSizes = useResponsiveFonts(desktopFontConfig, mobileFontConfig, availableHeight, availableWidth, 800, 1200, 600, 400, 12, 72, 768);

  return (
    <div
      className="flex flex-col items-center mt-[9rem]"
      style={{
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Box display="flex" flexDirection="column" textAlign="center" className="gap-6 md:gap-10" mt={10}>
        {/* Question Text */}
        <Text fontWeight="normal" mb={6} className="text-center text-[#0B1838]" style={{ fontSize: fontSizes.question }}>
          Are you currently taking anti-obesity medications?
        </Text>

        {/* Radio Button Options for Yes/No */}
        <div className="w-full flex justify-center items-center gap-10 md:gap-20">
          {/* Yes Option */}
          <label className="flex items-center gap-4 cursor-pointer" onClick={() => handleFieldUpdate(true)}>
            <input type="radio" name="antiObesityMedications" value="true" checked={selectedValue === true} onChange={() => handleFieldUpdate(true)} className="hidden" id="antiObesityYes" />
            {selectedValue === true ? (
              // Selected SVG for Yes
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="#F1F4FF" stroke="url(#paint0_linear_yes_selected)" strokeWidth="1.2968" />
                <rect x="6.8418" y="6.96777" width="12.9162" height="13.02" rx="2.5936" fill="#0B1838" />
                <defs>
                  <linearGradient id="paint0_linear_yes_selected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B3A67" />
                    <stop offset="1" stopColor="#F0AB35" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              // Not selected SVG for Yes
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="white" stroke="url(#paint0_linear_yes_unselected)" strokeWidth="1.2968" />
                <defs>
                  <linearGradient id="paint0_linear_yes_unselected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B3A67" />
                    <stop offset="1" stopColor="#F0AB35" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            <span className="text-2xl" style={{ fontSize: fontSizes.buttonText }}>
              Yes
            </span>
          </label>

          {/* No Option */}
          <label className="flex items-center gap-4 cursor-pointer" onClick={() => handleFieldUpdate(false)}>
            <input
              type="radio"
              name="antiObesityMedications"
              value="false"
              checked={selectedValue === false}
              onChange={() => handleFieldUpdate(false)}
              className="hidden" // Hide the native radio
              id="antiObesityNo"
            />
            {selectedValue === false ? (
              // Selected SVG for No
              <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="#F1F4FF" stroke="url(#paint0_linear_no_selected)" strokeWidth="1.2968" />
                <rect x="6.8418" y="6.96777" width="12.9162" height="13.02" rx="2.5936" fill="#0B1838" />
                <defs>
                  <linearGradient id="paint0_linear_no_selected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B3A67" />
                    <stop offset="1" stopColor="#F0AB35" />
                  </linearGradient>
                </defs>
              </svg>
            ) : (
              // Not selected SVG for No
              <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.03219" y="1.10543" width="24.5356" height="24.7432" rx="4.5388" fill="white" stroke="url(#paint0_linear_no_unselected)" strokeWidth="1.2968" />
                <defs>
                  <linearGradient id="paint0_linear_no_unselected" x1="22.7309" y1="6.6244" x2="37.7044" y2="11.8833" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B3A67" />
                    <stop offset="1" stopColor="#F0AB35" />
                  </linearGradient>
                </defs>
              </svg>
            )}
            <span className="text-2xl" style={{ fontSize: fontSizes.buttonText }}>
              No
            </span>
          </label>
        </div>
      </Box>
    </div>
  );
};

export default AntiObesityMedicationQuestion;
